*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul, li {
  font-size: 100%;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 10px;
}

html, body {
  background: #fefefe;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-wrap: pretty;
  font-family: Just Another Hand, cursive;
  font-weight: 100;
}

h2 {
  font-size: 3.5em;
}

p, li {
  text-wrap: pretty;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong, b {
  font-weight: 700;
}

em, i {
  font-style: italic;
}

.page {
  background: #fff;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 50px #0000001a;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0;
}

@media screen and (width >= 1400px) {
  .page {
    margin: 5em auto;
  }
}

.hero {
  color: #fff;
  background: #040404 center / cover no-repeat;
  display: flex;
  position: relative;
}

.hero:after {
  content: "";
  background: #0a010a4d;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__content {
  color: #fff;
  z-index: 1;
  background: #0a010a66;
  gap: 1em;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.hero__wrapper {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  width: 100%;
  height: 100%;
  padding: 4em;
  font-size: 11px;
  display: flex;
}

.hero__wrapper h2 {
  font-size: 3.8em;
}

.hero__wrapper p {
  font-size: 1.4em;
  font-weight: 300;
}

.hero__wrapper a {
  color: #b5e5b5;
  text-shadow: 0 0 2px #000;
  font-weight: 600;
}

@media screen and (width >= 768px) {
  .hero__content {
    width: 50%;
    height: 450px;
  }

  .hero__wrapper {
    text-align: left;
    justify-content: flex-end;
    max-width: 550px;
  }

  .hero--left {
    justify-content: flex-start;
  }

  .hero--left .hero__content {
    justify-content: flex-end;
    padding-right: 5vw;
  }

  .hero--right {
    justify-content: flex-end;
  }

  .hero--right .hero__content {
    justify-content: flex-start;
    padding-right: 5vw;
  }
}

@media screen and (width >= 1400px) {
  .hero {
    overflow: hidden;
  }
}

.Rose {
  z-index: 0;
  opacity: .03;
  pointer-events: none;
  width: 50%;
  position: fixed;
}

.Rose:first-child {
  top: -20%;
  right: -12%;
}

.Rose:nth-child(2) {
  bottom: 20%;
  left: -50%;
}

.Header {
  padding: .5em 1.5em;
}

.Header h1, .Header h2 {
  color: #000;
  margin: 0;
  font-family: sans-serif;
  font-weight: 500;
}

.Header__logo svg {
  width: 120px;
  height: 120px;
}

.Header__container {
  max-width: 100%;
}

.Header__grid {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  width: 100%;
  display: grid;
}

.Header__logo {
  text-align: center;
  position: relative;
}

.Header__logo svg .rose {
  fill: #df1af0;
}

.Header__items {
  flex-direction: column;
  justify-content: center;
  font-family: sans-serif;
  font-size: 1em;
  line-height: 1.9;
  display: flex;
}

.Header__items h1, .Header__items h2 {
  text-align: left;
}

.Header__items:last-child {
  flex-direction: column;
  align-items: flex-end;
}

.Header__items:last-child h1, .Header__items:last-child h2 {
  text-align: right;
}

.Header__socials svg {
  opacity: .8;
  width: 32px;
  transition: all .25s;
  display: block;
}

.Header__socials svg * {
  fill: #3ba239;
  transition: all .25s;
}

.Header__socials li:first-child svg {
  width: 29px;
}

.Header__socials a:hover svg {
  opacity: 1;
  scale: 1.1;
}

.Header__socials a:hover svg * {
  fill: #297228;
}

@media screen and (width >= 768px) {
  .Header {
    padding: 1em 3em;
  }

  .Header__logo svg {
    width: 150px;
    height: 150px;
  }

  .Header__items {
    font-size: 1.2em;
  }
}

.Slider {
  width: 100%;
  overflow: hidden;
}

.Slider__container {
  max-width: 100%;
  position: relative;
}

#slider {
  aspect-ratio: 16 / 9;
  background: #040404;
  width: 100%;
  max-height: 500px;
  position: relative;
}

#slider .Slider__picture-8 img {
  object-position: left 65%;
}

#slider .Slider__picture-5 img {
  object-position: left 35%;
}

#slider picture, #slider img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

#slider .enter-active {
  transition: all 1.5s;
}

#slider .leave-active {
  transition: all 2s;
}

#slider .enter-from {
  opacity: 0;
  rotate: -5deg;
  scale: 1.5;
}

#slider .enter-to {
  opacity: 1;
  scale: 1;
}

#slider .leave-from {
  opacity: 1;
}

#slider .leave-to {
  opacity: 0;
  rotate: 5deg;
  scale: 3;
}

.News {
  color: #fff;
  text-align: center;
  z-index: 10;
  background: #040404;
  border: 3px solid #ffffff80;
  border-radius: 9999px;
  align-items: center;
  width: 175px;
  height: 175px;
  margin-top: -15%;
  margin-left: -1%;
  padding: 3.5em;
  line-height: 1;
  display: flex;
  position: absolute;
  rotate: -8deg;
  scale: .75;
}

.News.primary {
  background: linear-gradient(0deg, #8e0a9a, #df1af0);
  box-shadow: 0 0 20px #8e0a9a80, 0 20px 40px #8e0a9a80;
}

.News.secondary {
  background: linear-gradient(0deg, #235f22, #3eab3c);
  box-shadow: 0 0 20px #235f2280, 0 20px 40px #235f2280;
}

.News__day {
  text-align: center;
  text-shadow: 0 0 2px #00000080;
  margin: -5px 0 -1px;
  font-family: Georgia, serif;
  font-size: 50px;
}

.News__month {
  text-align: center;
  opacity: .6;
  font-family: Georgia, serif;
  font-size: 20px;
}

.News__place {
  text-transform: uppercase;
  margin-top: .7em;
  font-size: 13px;
}

@media screen and (width >= 648px) {
  .News {
    margin-top: -125px;
    margin-left: 2%;
    scale: .9;
  }
}

@media screen and (width >= 1280px) {
  .News {
    margin-top: -7%;
    margin-left: 5%;
    scale: 1;
  }
}

.Features img {
  object-fit: contain;
  width: 100px;
  height: 100px;
  padding: 1em;
}

.Features h2 {
  color: #3eab3c;
}

.Features p {
  color: #000;
  font-size: clamp(1.4em, 1.75vw, 1.5em);
  font-weight: 300;
}

.Features__container {
  padding: 4.5em 1em;
}

.Features__grid {
  flex-direction: column;
  align-items: center;
  gap: 2em 4em;
  display: flex;
}

.Features__grid__col {
  text-align: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
}

@media (width >= 768px) {
  .Features__grid {
    flex-direction: row;
    align-items: flex-start;
  }

  .Features__grid__col {
    width: 33%;
  }
}

.Location {
  background: #2c3104 0 0 / cover no-repeat;
}

.Location address {
  color: #ffffffd9;
  margin-top: 1em;
  font-size: 1.5em;
}

.Location address strong {
  color: #fff;
}

.Location a {
  z-index: 10;
  display: block;
  position: relative;
}

.Location-grasse {
  background-image: url("bg-grasse.f97b5ca8.jpeg");
  background-position: 50% 55%;
}

.Location-cabris {
  background-image: url("bg-cabris.f3938202.jpeg");
  background-position: 50% 55%;
}

.Events {
  background-color: #170c01;
  background-image: image-set("bg-atelier.5d9fdd5d.jpeg" 1x, "bg-atelier.c89ba62d.jpeg" 2x);
  background-position: 50% 70%;
}

.Events li {
  margin-left: .75em;
  font-size: 1.4em;
  font-weight: 300;
  list-style: circle;
}

.Events address {
  color: #ffffffd9;
  margin-top: 1em;
  font-size: 1.5em;
}

.Events address strong {
  color: #fff;
}

.Partners {
  background: #fdf3fe;
}

.Partners h2 {
  color: #3eab3c;
  text-align: center;
  display: none;
}

.Partners__container {
  padding: 3em 1em;
}

.Partners__grid {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3em;
  margin-top: 2em;
  padding: 1em;
  display: flex;
}

.Partners__item {
  filter: grayscale(90%);
  mix-blend-mode: darken;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  display: flex;
}

.Partners__item:hover {
  filter: grayscale(0);
  opacity: 1;
  transform: scale(1.1);
}

.Partners__item img {
  width: 100%;
  max-width: 120px;
}

@media screen and (width >= 768px) {
  .Partners__item img {
    max-width: 125px;
  }
}

.Footer {
  color: #fff;
  isolation: isolate;
  background: #040404 image-set("bg-footer.fad85e38.jpeg" 1x, "bg-footer.fad85e38.jpeg" 2x) center / cover no-repeat;
  position: relative;
}

.Footer:before {
  content: "";
  background: #0a010ad9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Footer h2 {
  font-size: 35px;
}

.Footer h3 {
  font-size: 24px;
}

.Footer__container {
  isolation: isolate;
  padding: 5em 4em;
}

.Footer_grid {
  text-align: center;
  flex-direction: column;
  gap: 2.5em;
  width: 100%;
  display: flex;
}

.Footer__logo {
  isolation: isolate;
  grid-area: logo;
  width: 100%;
  height: 100%;
  margin-bottom: 1em;
}

.Footer__logo svg {
  width: 100%;
  max-width: 125px;
  height: auto;
}

.Footer__logo svg * {
  fill: #fff;
}

.Footer__logo svg .rose, .Footer__logo svg .club, .Footer__logo svg .dash {
  opacity: .65;
}

.Footer__logo svg .rose {
  fill: #f3aaf9;
  opacity: 1;
}

.Footer__openings dl {
  font-size: 12px;
}

.Footer__openings dt {
  font-weight: 600;
}

.Footer__openings dd {
  opacity: .8;
  margin-bottom: .5em;
  font-size: 11px;
}

.Footer__address address {
  color: #ffffffb3;
  margin-bottom: .75em;
  font-size: 11px;
}

.Footer__address address strong {
  color: #fff;
  font-weight: 500;
}

.Footer__address address small {
  color: #7ed17c;
  text-transform: uppercase;
  font-size: 9px;
}

.Footer__credits {
  opacity: .5;
  text-align: right;
  margin-top: 5em;
}

.Footer__address {
  grid-area: address;
}

.Footer__contact {
  grid-area: contact;
}

.Footer__openings {
  grid-area: openings;
}

@media screen and (width >= 760px) {
  .Footer__logo {
    text-align: center;
  }

  .Footer_grid {
    text-align: left;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "logo address openings"
                         "logo contact openings"
                         "logo \- openings";
    display: grid;
  }

  .Footer__grasse {
    text-align: right;
  }

  .Footer__cabris {
    text-align: left;
  }
}

@media screen and (width >= 960px) {
  h3, h2 span {
    display: initial;
  }

  .Footer__logo, .Footer__grasse, .Footer__cabris {
    text-align: left;
  }

  .Footer_grid {
    text-align: left;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "logo address openings"
                         "logo contact openings"
                         "logo \- openings";
    gap: 1em 3em;
    display: grid;
  }
}
/*# sourceMappingURL=index.87ddabc2.css.map */
