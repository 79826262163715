
.Events
  background-color: #170c01
  background-position: 50% 70%
  background-image: url("/img/bg-atelier.jpg")
  background-image: image-set("/img/bg-atelier.jpg?width=1200" 1x, "/img/bg-atelier.jpg" 2x)

  li
    font-size: 1.4em
    font-weight: 300
    list-style: circle
    margin-left: .75em

  address
    margin-top: 1em
    font-size: 1.5em
    color: rgba(white, .85)

    strong
      color: white
