*, *::before, *::after
  box-sizing: border-box
  margin: 0
  padding: 0
  font-weight: normal
  font-style: normal

body, h1, h2, h3, h4, h5, h6, p, ol, ul, li
  font-size: 100%
  font-weight: normal

ol, ul
  list-style: none

a
  text-decoration: none
  color: inherit

input, textarea, select, button
  font-family: inherit
  font-size: inherit
  line-height: inherit
