.hero
  background: $dark no-repeat center center
  background-size: cover
  position: relative
  color: white
  display: flex

  &:after
    content: ""
    background: rgba(darken($primary, 50), 0.3)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.hero__content
  background: rgba(darken($primary, 50), 0.4)
  gap: 1em
  color: white
  position: relative
  z-index: 1
  height: 100%
  display: flex
  width: 100%

.hero__wrapper
  padding: 4em
  height: 100%
  width: 100%
  display: flex
  gap: 1em
  justify-content: flex-end
  flex-direction: column
  font-size: 11px
  text-align: center
  justify-content: center

  h2
    font-size: 3.8em

  p
    font-size: 1.4em
    font-weight: 300

  a
    color: lighten($secondary, 35%)
    text-shadow: 0 0 2px black
    font-weight: 600

@media screen and (min-width: 768px)
  .hero__content
    width: 50%
    height: 450px

  .hero__wrapper
    justify-content: flex-end
    text-align: left
    max-width: 550px

  .hero--left
    justify-content: flex-start

    .hero__content
      justify-content: flex-end
      padding-right: 5vw

  .hero--right
    justify-content: flex-end

    .hero__content
      padding-right: 5vw
      justify-content: flex-start

@media screen and (min-width: $container-max)
  .hero
    overflow: hidden
