
html
  font-size: 10px
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",sans-serif

html, body
  margin: 0
  padding: 0
  height: 100%
  line-height: 1.5
  background: #fefefe

h1, h2, h3, h4, h5, h6
  font-weight: 100
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-wrap: pretty
  font-family: "Just Another Hand", cursive

h2
  font-size: 3.5em

p, li
  text-wrap: pretty
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

strong, b
  font-weight: 700

em, i
  font-style: italic
