.page
  max-width: $container-max
  margin: 0 auto
  background: $background
  box-shadow: 0 0 50px rgba(black, 0.1)
  overflow: hidden
  position: relative
  background: white

.container
  max-width: 1100px
  margin: 0 auto
  padding: 0 0

@media screen and (min-width: $container-max)
  .page
    margin: 5em auto
