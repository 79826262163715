.Partners
  background: #fdf3fe

  h2
    color: $secondary
    text-align: center
    display: none

.Partners__container
  padding: 3em 1em

.Partners__grid
  padding: 1em
  display: flex
  gap: 3em
  flex-wrap: wrap
  justify-content: center
  align-items: center
  margin-top: 2em

.Partners__item
  display: flex
  align-items: center
  justify-content: center
  filter: grayscale(90%)
  transition: all .5s ease
  mix-blend-mode: darken

  &:hover
    filter: grayscale(0)
    opacity: 1
    transform: scale(1.1)

  img
    width: 100%
    max-width: 120px

@media screen and (min-width: 768px)
  .Partners__item img
    max-width: 125px
