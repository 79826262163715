.Location
  background: #2c3104
  background-repeat: no-repeat
  background-size: cover

  address
    margin-top: 1em
    font-size: 1.5em
    color: rgba(white, .85)

    strong
      color: white

  a
    display: block
    position: relative
    z-index: 10

.Location-grasse
  background-image: url("/img/bg-grasse.jpg")
  background-position: center 55%

.Location-cabris
  background-image: url("/img/bg-cabris.jpg")
  background-position: center 55%
