.Header
  padding: .5em 1.5em

  h1, h2
    font-family: sans-serif
    color: black
    font-weight: 500
    margin: 0

.Header__logo svg
  width: 120px
  height: 120px

.Header__container
  max-width: 100%

.Header__grid
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 1em
  width: 100%

.Header__logo
  text-align: center
  position: relative

  svg
    .rose
      fill: $primary

.Header__items
  display: flex
  justify-content: center
  flex-direction: column
  font-size: 1em
  line-height: 1.9
  font-family: sans-serif

  h1, h2
    text-align: left

.Header__items:last-child
  align-items: flex-end
  flex-direction: column

  h1, h2
    text-align: right

.Header__socials
  // margin-bottom: -5em
  // margin-right: -.4em

  // ul
  //   display: flex

  svg
    width: 32px
    opacity: .8
    transition: .25s
    display: block

    *
      transition: .25s
      fill: darken($secondary, 2.5%)

  li:first-child svg
    width: 29px

  a:hover
    svg
      opacity: 1
      scale: 1.1

      *
        fill: darken($secondary, 15%)

@media screen and (min-width: 768px)
  .Header
    padding: 1em 3em

  .Header__logo svg
    width: 150px
    height: 150px

  .Header__items
    font-size: 1.2em
