.Slider
  width: 100%
  overflow: hidden

.Slider__container
  max-width: 100%
  position: relative

#slider
  aspect-ratio: 16/9
  position: relative
  max-height: 500px
  width: 100%
  background: $dark

  .Slider__picture-8 img
    object-position: left 65%

  .Slider__picture-5 img
    object-position: left 35%

  // .Slider__picture-3 img
  //   object-position: left 90%

  // .Slider__picture-1 img
  //   object-position: left 25%

  // .Slider__picture-5 img
  //   object-position: left 35%

  // .Slider__picture-2 img
  //   object-position: left 10%

  // .Slider__picture-6 img
  //   object-position: left 100%

  picture,
  img
    position: absolute
    object-fit: cover
    width: 100%
    height: 100%

  .enter-active
    transition: 1.5s

  .leave-active
    transition: 2s

  .enter-from
    opacity: 0
    scale: 1.5
    rotate: -5deg

  .enter-to
    opacity: 1
    scale: 1

  .leave-from
    opacity: 1

  .leave-to
    opacity: 0
    scale: 3
    rotate: 5deg

// .Slider__text
//   position: absolute
//   z-index: 10
//   color: white
//   inset: 0 0 0 50%
//   background: linear-gradient(80deg, transparent, rgba(darken($primary, 100), 0.8))
//   padding: 4em
//   display: flex
//   align-items: flex-end
//   text-align: right
//   justify-content: flex-end
//   display: none

//   h1, h2
//     font-size: 5em
//     line-height: 1
//     margin-bottom: .1em
//     font-weight: 600
//     font-family: Helvetica, "Trebuchet MS", Verdana, sans-serif

//   h2
//     font-size: 3em
//     margin-bottom: .5em

//   p
//     font-size: 2.25rem
//     line-height: 1.5
//     font-weight: 300
