.Rose
  position: fixed
  z-index: 0
  opacity: 0.03
  pointer-events: none
  width: 50%

.Rose:first-child
  top: -20%
  right: -12%

.Rose:nth-child(2)
  bottom: 20%
  left: -50%
