.News
  background: $dark
  color: white
  width: 175px
  height: 175px
  border-radius: 9999px
  text-align: center
  display: flex
  align-items: center
  padding: 3.5em
  line-height: 1
  border: 3px solid rgba(white, .5)
  position: absolute
  margin-top: -15%
  margin-left: -1%
  z-index: 10
  rotate: -8deg
  scale: .75

  &.primary
    background: linear-gradient(0deg, darken($primary, 20%), $primary,)
    box-shadow: 0 0 20px rgba(darken($primary, 20), 0.5), 0 20px 40px rgba(darken($primary, 20), 0.5)

  &.secondary
    background: linear-gradient(0deg, darken($secondary, 20%), $secondary,)
    box-shadow: 0 0 20px rgba(darken($secondary, 20), 0.5), 0 20px 40px rgba(darken($secondary, 20), 0.5)

.News__day
  margin: -5px 0 -1px
  font-size: 50px
  text-align: center
  font-family: Georgia, serif
  text-shadow: 0 0 2px rgba(black, .5)

.News__month
  font-size: 20px
  text-align: center
  font-family: Georgia, serif
  opacity: .6

.News__place
  margin-top: .7em
  text-transform: uppercase
  font-size: 13px

@media screen and (min-width: 648px)
  .News
    margin-top: -125px
    margin-left: 2%
    scale: .9

@media screen and (min-width: 1280px)
  .News
    margin-top: -7%
    margin-left: 5%
    scale: 1
