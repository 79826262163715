.Footer
  background: $dark no-repeat center center
  background-size: cover
  background-image: url("/img/bg-footer.jpg")
  background-image: image-set("/img/bg-footer.jpg?width=1280" 1x, "/img/bg-footer.jpg" 2x)
  color: white
  position: relative
  isolation: isolate

  &:before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(darken($primary, 50), 0.85)

  h2
    font-size: 35px
    // opacity: .75

  h3
    font-size: 24px
    // display: none

.Footer__container
  isolation: isolate
  padding: 5em 4em

.Footer_grid
  width: 100%
  display: flex
  flex-direction: column
  gap: 2.5em
  text-align: center

.Footer__logo
  grid-area: logo
  width: 100%
  height: 100%
  isolation: isolate
  margin-bottom: 1em

  svg
    width: 100%
    max-width: 125px
    height: auto

    *
      fill: white

    .rose, .club, .dash
      opacity: .65

    .rose
      fill: lighten($primary, 30)
      opacity: 1

.Footer__openings
  dl
    font-size: 12px

  dt
    font-weight: 600

  dd
    font-size: 11px
    margin-bottom: .5em
    opacity: .8

.Footer__address
  address
    color: rgba(white, .7)
    margin-bottom: .75em
    font-size: 11px

    strong
      color: white
      font-weight: 500

    small
      color: lighten($secondary, 20%)
      text-transform: uppercase
      font-size: 9px

.Footer__credits
  opacity: .5
  text-align: right
  margin-top: 5em

.Footer__address
  grid-area: address

.Footer__contact
  grid-area: contact

.Footer__openings
  grid-area: openings

@media screen and (min-width: 760px)
  .Footer__logo
    text-align: center

  .Footer_grid
    text-align: left
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-template-areas: "logo address openings" "logo contact openings" "logo - openings"

  .Footer__grasse
    text-align: right

  .Footer__cabris
    text-align: left

@media screen and (min-width: 960px)
  h3, h2 span
    display: initial

  .Footer__logo
    text-align: left

  .Footer__grasse
    text-align: left

  .Footer__cabris
    text-align: left

  .Footer_grid
    text-align: left
    display: grid
    gap: 1em 3em
    grid-template-columns: 1fr 1fr 1fr
    grid-template-areas: "logo address openings" "logo contact openings" "logo - openings"
