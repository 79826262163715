.Features
  img
    height: 100px
    width: 100px
    object-fit: contain
    padding: 1em

  h2
    color: $secondary

  p
    font-weight: 300
    color: $text
    font-size: clamp(1.4em, 1.75vw, 1.5em)

.Features__container
  padding: 4.5em 1em

.Features__grid
  display: flex
  flex-direction: column
  gap: 2em 4em
  align-items: center

.Features__grid__col
  display: flex
  flex-direction: column
  align-items: center
  align-content: center
  text-align: center
  max-width: 400px
  margin: 0 auto

@media (min-width: 768px)
  .Features__grid
    flex-direction: row
    align-items: flex-start

  .Features__grid__col
    width: 33%
